import React, { useState,useEffect } from "react"
import "antd/dist/antd.css";
import {ArrowLeftOutlined} from '@ant-design/icons'
import { Form, Input, Button, Select, Tooltip, Divider, Space,message } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Item from "antd/lib/list/Item";
import { CookiesProvider, useCookies } from 'react-cookie';
require('./sub.routes.css')
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const ClientCreate=()=>{
    const location=useLocation()
    const [form] = Form.useForm();
    const navigation=useNavigate()
    const [dealerData,setdealerData]=useState([])
    const [accountData,setAccountData]=useState([])
    const [versionData,setVersionData]=useState([])
    const [DatabaseData,setDatabaseData]=useState([])
    const [cookies, setCookie,removeCookie] = useCookies(['user']);
    const [taxes,setTaxes]=useState([])
    const onFinish = (values) => {
      const hide = message.loading('Action in progress..', 0);
      if(location.state){
        axios.put('https://activatewm.com/portal/v1/client/update?client_id='+location.state.data.client_id,{
          dealer_id:values.Dealer,
          client_name:values.Name,
          email:values.email|| " ",
          mobile:values.mobile|| " ",
          dealership:"0",
          customer_id:cookies.customer_id,
          location:values.location,
          account:values.Account,
          database:values.database,
          version:values.version,
          location:values.location|| " ",
          account:values.Account|| " ",
          database:values.database|| " ",
          tax_id:values.Tax|| " ",
          version:values.version|| " ",
          status:values['Status']=="active"?true:false
        }).then(res=>{
          setTimeout(hide,100)

          if(res.data.success){
            message.success('Updated Successful')
          }else{
            message.error("Updating failed")
          }
        }).catch(err=>{
          setTimeout(hide,100)
          err.message==='Request failed with status code 400'? message.info("Email already Registered"):message.error('Internal server issue')
        })
      }else{
      axios.post('https://activatewm.com/portal/v1/client/create',{
        dealer_id:values.Dealer,
        client_name:values.Name,
        email:values.email|| " ",
        mobile:values.mobile|| " ",
        dealership:"0",
        location:values.location,
        account:values.Account,
        customer_id:cookies.customer_id,
        database:values.database,
        version:values.version,
        status:values['Status']=="active"?true:false,
        customer_id:cookies.customer_id,
      }).then(res=>{
        setTimeout(hide,100)
        if(!res.data.success){
          message.success('Created')
        }else{
          message.error("Email already Registered")
        }
      }).catch(err=>{
        setTimeout(hide,100)
        err.message==='Request failed with status code 400'? message.info("Email already Registered"):message.error('Internal server issue')
      })
    }
      };
      const fetch=()=>{
        axios.get('https://activatewm.com/portal/v1/dealer/get?customer_id='+cookies.customer_id) .then(res=>{
          setdealerData(res.data)
        }).catch(err=>{
          message.error('internal error')
        })
        axios.get('https://activatewm.com/portal/v1/options/accounting/get?customer_id='+cookies.customer_id).then(res=>{ 
          setAccountData(res.data)
        })
        .catch(err=>{
          message.error('internal error')
        })
        axios.get('https://activatewm.com/portal/v1/options/versionsystem/get?customer_id='+cookies.customer_id).then(res=>{ 
          setVersionData(res.data)
        })
        .catch(err=>{
          message.error('internal error')
        })
        axios.get('https://activatewm.com/portal/v1/options/databasesystem/get?customer_id='+cookies.customer_id).then(res=>{ 
          setDatabaseData(res.data)
        })
        .catch(err=>{
          message.error('internal error')
        })
         axios.get('https://activatewm.com/portal/v1/options/tax/get?customer_id='+cookies.customer_id).then(res=>{
          setTaxes(res.data)
         }).catch(err=>{
          message.error('internal error')
        })
     
      }
      useEffect(()=>{
      
        fetch()
      },[])  

const onReset = () => {
        form.resetFields();
      };
    
    return (
      <div className="main-create  w3-card ">
      <div className="header">
      <div className="back">
      <Tooltip title="Back">
      <Button type="primary" shape="circle" icon={<ArrowLeftOutlined />} onClick={()=>{
         navigation('/clients',{replace:true})
      }}/>
    </Tooltip>    
      </div>
      </div>
      <Space />
      <Space />
      <Divider /> 
      <div className="data-form">
      <Form 
      initialValues={location.state?{
        "Name":location.state.data.client_name,"mobile":location.state.data.mobile,"email":location.state.data.email,
        "location":location.state.data.location,"Dealer":location.state.data.dealer?location.state.data.dealer._id:"",
        "Account":location.state.data.accountingSystem?location.state.data.accountingSystem._id:"",
        "database":location.state.data.databaseSystem?location.state.data.databaseSystem._id:"",
        "version":location.state.data.versionnumber?location.state.data.versionnumber._id:"",
        "Tax":location.state.data.tax?location.state.data.tax._id:"",
        'Status':location.state.data.status?"active":"deactive"
      }:""}
      {...layout} 
      form={form} 
      labelAlign='left'
      name="control-hooks" 
      onFinish={onFinish}>
      <Form.Item
      style={{ marginBottom: 4 }}
        name="Dealer"
        label="Associated Dealer"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          value={"voso"}
          placeholder="Select a Dealer"
        >
         {
           dealerData.map(item=>{
             if(item.status){
              return <Option value={item._id}>{item.dealer_name}</Option>
             }
           })
         }
        </Select>
      </Form.Item>  
     <Form.Item
     style={{ marginBottom: 4 }}
        name="Name"
        label="Customer Name"
        rules={[
          {
            required: true,
          },
        ]}
      >
      <Input />
      </Form.Item>
      <Form.Item
      style={{ marginBottom: 4 }}
        name="mobile"
        label="Telephone"
        rules={[
          {
            required: false,
          },
        ]}
      ><Input/>
      </Form.Item>  
      <Form.Item
      style={{ marginBottom: 4 }}
        name="email"
        label="Email"
        rules={[
          {
            required: false,
          },
        ]}
      ><Input/>
      </Form.Item> 
      <Form.Item
      style={{ marginBottom: 4 }}
        name="location"
        label="Location"
        rules={[
          {
            required: false,
          },
        ]}
      ><Input/>
      </Form.Item> 
      <Form.Item
      style={{ marginBottom: 4 }}
        name="Account"
        label="Accounting Software"
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Select
          placeholder="Select Accounting from below list"
        >
         {
           accountData.map(item=>{
            if(item.status){
              return <Option value={item._id}>{item.name}</Option>
             }
           })
         }
        </Select>
      </Form.Item> 
      <Form.Item
      style={{ marginBottom: 4 }}
        name="Tax"
        label="Taxes"
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Select
          placeholder="Select Tax from below list"
        >
         {
           taxes.map(item=>{
            if(item.status){
              return <Option value={item._id}>{item.name}</Option>
             }
           })
         }
        </Select>
      </Form.Item>  
      <Form.Item
      style={{ marginBottom: 4 }}
        name="database"
        label="Database"
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Select
          placeholder="Select Database type from below list"
        >
         {
           DatabaseData.map(item=>{
            if(item.status){
              return <Option value={item._id}>{item.name}</Option>
             }
           })
         }
        </Select>
      </Form.Item>       
      <Form.Item
      style={{ marginBottom: 4 }}
        name="version"
        label="Software Version"
        rules={[
          {
            required: false,
          },
        ]}
      >
        <Select
          placeholder="Select Version Number from below list"
        >
         {
           versionData.map(item=>{
            if(item.status){
              return <Option value={item._id}>{item.name}</Option>
             }
           })
         }
        </Select>
      </Form.Item>       
      <Form.Item
        style={{ marginBottom: 4 }}
        name="Status"
        label="Status"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder="Select a option and change input text above"
        >
          <Option value="active">Active</Option>
          <Option value="deactive">Deactive</Option>
        </Select>
      </Form.Item>
    
      <Divider /> 
      
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit" style={{marginRight:10}}>
          {location.state?"Update":"Add"} 
        </Button>
        <Button htmlType="button" onClick={onReset}>
          Reset
        </Button>
       
      </Form.Item>

    </Form>
      </div>
     </div>
      )
}
export default ClientCreate

