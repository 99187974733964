
import './App.css';
import {Route,Routes, useNavigate} from 'react-router-dom';
import Login from './components/login';
import Home from './components/home';
import {  useEffect, useState } from 'react';
import "antd/dist/antd.css";
import DatabaseType from './components/routes/databaseType';
import DeviceType from './components/routes/deviceType';
import OperatingSystem from './components/routes/operatingSystem';
import AccountingSystem from './components/routes/accountingSystem';
import VersionNumber from './components/routes/versionNumber';
import Dashboard from './components/routes/dashboard';
import Admins from './components/routes/admins';
import Clients from './components/routes/clients';
import Dealers from './components/routes/dealers';
import AccountingCreate from './components/routes/sub_routes/create.accounting';
import DatabaseCreate from './components/routes/sub_routes/create.database';
import DeviceCreate from './components/routes/sub_routes/create.device';
import OperatingSystemCreate from './components/routes/sub_routes/create.operating';
import VersionCreate from './components/routes/sub_routes/create.version';
import DealerCreate from './components/routes/sub_routes/create.dealer';
import { CookiesProvider, useCookies } from 'react-cookie';
import ClientCreate from './components/routes/sub_routes/create.client';
import Devices from './components/routes/devices';
import LicenseCreate from './components/routes/sub_routes/license.create';
import AdminCreate from './components/routes/sub_routes/create.admin';
import SoftwareType from './components/routes/softwaretype';
import SoftwareTypeCreate from './components/routes/sub_routes/create.SoftwareType';
import AutoInvoice from './components/AutoInovice';
import Taxes from './components/routes/taxes';
import TaxCreate from './components/routes/sub_routes/create.tax';

function App() {
  const [token, setToken] = useState(null);
  const navigation=useNavigate()
  const [cookies,setCookies]=useCookies(['user'])
 useEffect(() => {
  window.addEventListener("beforeunload", alertUser);
  return () => {
    window.removeEventListener("beforeunload", alertUser);
  };
}, []);
const alertUser = (e) => {
  e.preventDefault();
  console.log(e)
  e.returnValue = "";
};
useEffect(()=>{
  console.log("check")
  if(cookies.id){
    setToken(cookies.id)
  }
})
  return (
    <div className="App">
      <CookiesProvider>
        <Routes  >
          {
            token? <Route   path='/' element={<Home setToken={setToken} />}>
                <Route path='/databasetype' element={<DatabaseType />} />
                <Route path='/devicetype' element={<DeviceType />} />
                <Route path='/operatingsystem' element={<OperatingSystem />} />
                <Route path='/accountingsystem' element={<AccountingSystem />} />
                <Route path='/versionnumber' element={<VersionNumber />} />
                <Route exact path='/' element={<Dashboard />} />
                {/* <Route path='/admins' element={<Admins />} /> */}
                <Route path='/clients' element={<Clients />} />
                <Route exact path='/databasetype/create' element={<DatabaseCreate />} />
                <Route exact path='/accountingsystem/create' element={<AccountingCreate />} />
                <Route exact path='/devicetype/create' element={<DeviceCreate />} />
                <Route exact path='/operatingsystem/create' element={<OperatingSystemCreate />} />
                <Route exact path='/versionnumber/create' element={<VersionCreate />} />
                <Route path='/dealers' element={<Dealers />} />
                <Route path='/dealers/create' element={<DealerCreate />} />
                <Route path='/clients/create' element={<ClientCreate />} />
                <Route path='/license' element={<Devices />} />
                <Route path='/license/create' element={<LicenseCreate />}/>
                <Route path='/admins/create' element={<AdminCreate />}/>
                <Route path='/SoftwareType' element={<SoftwareType />}/>
                <Route path='/taxes' element={<Taxes />}/>
                <Route path='/taxes/create' element={<TaxCreate />}/>
                <Route path='/SoftwareType/create' element={<SoftwareTypeCreate/>}/>
                <Route path='/autoinvoice' element={<AutoInvoice/>} />
            </Route>
            :<Route exact  path='/' element={<Login setToken={setToken} />}/>
          }    
        </Routes>
        </CookiesProvider>
    </div>
  );
}

export default App;
