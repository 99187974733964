import React,{useState,useEffect} from "react"
import {Input, Space,message} from 'antd';
import "antd/dist/antd.css";
import {PlusOutlined} from '@ant-design/icons'
import { useNavigate } from "react-router-dom";
import { Table, Tag,} from 'antd';
import axios from "axios";
import moment from "moment";
import { useCookies } from "react-cookie";
require('./routes.css')
const { Column, ColumnGroup } = Table;

const DeviceType=()=>{
  const[data,setData]=useState([])
const[temp,setTemp]=useState([])
const [cookies, setCookie, removeCookie] = useCookies(['user']);
  const fetch=async()=>{
    const hide = message.loading('Action in progress..', 0);
    try {
      const payload=await axios.get('https://activatewm.com/portal/v1/options/devicesystem/get?customer_id='+cookies.customer_id)
      if(payload){
        setTimeout(hide,100)
        setData(payload.data)
        setTemp(payload.data)
      }
    } catch (error) {
        
    }
}
useEffect(()=>{
  fetch()
 },[])
  const navigation=useNavigate()
  const onSearch = value => {
    if(value.length==0){
      setData(temp)
    }else{
     let matched=temp.filter((item)=>{
        if(item.name.match(value)){
          return item
        }
      })
      setData(matched)
    }
  };
    return (
      <div className="main w3-card">
      <div className="header">
      <h5>Device Type</h5>
      <div className="create">
      <PlusOutlined className='plus' onClick={()=>{navigation('/devicetype/create')}}/>
      <Input.Search placeholder="search by title" allowClear onSearch={onSearch} style={{ width: 200 }} />
      </div>
      </div>
      <div className="body">
      <Table key='index' pagination={{pageSize:5}} className="tab" dataSource={data}>
      <Column title="Name" dataIndex="name" key="name" />
      <Column title="Status" dataIndex="status" render={(value,record)=>{
        return < Tag>{value==true?'active':'disabled'}</Tag>
      }}/>
      <Column title='Actions' render={(value,record)=>{
        return<Space>
           <Tag style={{cursor:'pointer'}} color={'Green'} onClick={()=>{
            navigation('/devicetype/create',{
              state:{
                data:{
                  _id:record._id,
                  name:record.name,
                  status:record.status
                }
              }
            })
           }}>Edit</Tag>
          <Tag style={{cursor:'pointer'}} color={'Red'} onClick={()=>{
              axios.delete('https://activatewm.com/portal/v1/options/devicesystem/delete?_id='+record._id).then(res=>{
                if(res.data.success){
                  fetch()
                  message.success('Delete successful')
                }else{
                  message.error('please try again')
                }
            }).catch(error=>message.error('please try again'))
          }}>Delete</Tag>
        </Space> 
      }}  />
      <Column title='Created-At' dataIndex='createdAt' key='createdAt' render={(value,record)=><p style={{paddingTop:16}} >{moment(value).format('DD-MM-YYYY')}</p>}  />
  </Table>
      </div>
     </div>
      )
}
export default DeviceType

