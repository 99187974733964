import React, { useEffect, useState } from "react"
import {Empty, Input, message, Pagination,Tag, Tooltip} from 'antd';
import "antd/dist/antd.css";
import {PlusOutlined,CheckOutlined,EditFilled,DeleteFilled} from '@ant-design/icons'
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useCookies } from "react-cookie";

require('./routes.css')
const style2 = { backgroundColor:'#dd4',alignItems: 'center',paddingLeft:6,paddingRight:20};
const style3={width:70,textAlign:'center',fontSize:16,backgroundColor:'#dd4',marginRight:10,borderRadius:20,cursor:'pointer'}

const Clients=()=>{
  const[data,setData]=useState([])
  const[temp,setTemp]=useState([])
  const [cookies, setCookie,removeCookie] = useCookies(['user']);
  const fetch=()=>{
    const hide = message.loading('Action in progress..', 0);
    axios.get('https://activatewm.com/portal/v1/client/fetch?customer_id='+cookies.customer_id)
    .then(res=>{
      setTimeout(hide,100)
      setData(res.data)
      setTemp(res.data)
    })
    .catch(err=>{
      setTimeout(hide,100)
      message.error('internal error')
    })
  }
  useEffect(()=>{
   fetch()
  },[])
  const navigation=useNavigate()
  const [min,setMin]=useState(0)
  const [max,setMax]=useState(4)
  const pageSize=4
  const onSearch = value => {
    if(value.length==0){
      setData(temp)
    }else{
     let matched=temp.filter((item)=>{
        if(item.client_name.match(value) ||item.email.match(value)){
          return item
        }
      })
      setData(matched)
    }
  };
    return (
      <div className="main w3-card" >
      <div className="header">
      <h5>clients</h5>
      <div className="create">
      <PlusOutlined className='plus' onClick={()=>{
        navigation('/clients/create')
        }} />
      <Input.Search placeholder="search name/email" allowClear onSearch={onSearch} style={{ width: 200 }} />
      </div>
      </div>
      <div >
        {
          data.length==0?<Empty/>:
          <>
          <div className="card" >
       {
        data.slice(min,max).map((v,i)=>{
          return   <div 
          className="client-card w3-card"
          >
             <div className="row">
            <b  >Total Devices</b>
            <p style={style2}>{v.licenses.count}</p>
          </div>
          <div className="row">
            <b >Used Devices</b>
            <p style={style2}>{v.licenses.used}</p>
          </div>
          <div className="row">
            <b >Expired Devices</b>
            <p style={{backgroundColor:'red',paddingLeft:6,paddingRight:20,alignItems: 'center', }}>{v.licenses.expired}</p>
          </div> 
        
          <div className="row">
            <b >Client Name</b>
            <p>{v.client_name}</p>
          </div>
          <div className="row">
            <b >Email</b>
            <p>{v.email}</p>
          </div>
          <div className="row">
            <b >Mobile</b>
            <p>{v.mobile}</p>
          </div>
          <div className="row">
            <b > Dealer Name :</b>
            <p>{v.dealer?v.dealer.dealer_name:"None"}</p>
          </div>
          <div className="row">
            <b >Dealer Email :</b>
            <p>{v.dealer?v.dealer.email:"None"}</p>
          </div>
          <div className="row">
            <b >Dealer Mobile :</b>
            <p>{v.dealer?v.dealer.mobile:"None"}</p>
          </div>
          <div className="row">
            <b >Accounting System :</b>
            <p>{v.accountingSystem?v.accountingSystem.name:"None"}</p>
          </div>
          <div className="row">
            <b >Database Type :</b>
            <p>{v.databaseSystem?v.databaseSystem.name:"None"}</p>
          </div>
          <div className="row">
            <b >Version Number :</b>
            <p>{v.versionnumber?v.versionnumber.name:"None"}</p>
          </div>
          <div className="row">
            <b >TAX TYPE:</b>
            <p>{v.tax?v.tax.name:"None"}</p>
          </div>
          <div className="row">
            <b >Status :</b>
            <Tag color={'green'}>{v.status==true?'active':'disabled'}</Tag>
          </div>
          <div className="row">
            <b >Created</b>
            <p>{moment(v.createdAt).format('DD:MM:YYYY MM:HH A')}.   </p>
          </div>
          <div className="row">
            <b >Modified</b>
            <p>{moment(v.updatedAt).format('DD:MM:YYYY MM:HH A')}.   </p>
          </div>
          <div className="row">
            <Tooltip title='Status change'>
            <b style={style3}
            onClick={()=>{
              axios.put('https://activatewm.com/portal/v1/client/update?client_id='+v.client_id,{
               status:v.status?false:true
             },{
                headers: {
                 'Accept': 'application/json',
                 'Content-Type': 'application/json'
               }
             }).then(data=>{
               if(data.data.success){
                 fetch()
               }
             }).catch(err=>{
               err.response.status===400?message.warning('failed'):message.error('Server error occur')
             })
           }}><CheckOutlined/></b>
            </Tooltip>
              <Tooltip title='Edit'>
              <b style={style3} onClick={()=>{
                navigation('/clients/create',{
                  state:{
                    data:v
                  }
                })
              }}><EditFilled/></b>
              </Tooltip>
              <Tooltip title='Devices'>

              <b style={style3} onClick={()=>{
              navigation('/license',{state:{
                c_id:v.client_id,
                d_id:v.dealer_id
              }})
                
              }}>Devices</b>
             </Tooltip>

              <Tooltip title='Delete'>
              <b style={style3}><DeleteFilled onClick={()=>{
                axios.delete('https://activatewm.com/portal/v1/client/delete?client_id='+v.client_id).then(res=>{
                  if(res.data.success){
                    message.success('Deleted')
                    fetch()
                  }else{
                    message.error('Sorry Check again')
                  }
                }).catch(err=>{
                  message.error('Internal Server issue')
                })
              }}/></b>
              </Tooltip>
             
            </div>
        </div>
        })
      }
       </div>
      <Pagination 
      style={{right:60,position:'absolute'}}
      onChange={(page)=>{
       setMax(page*pageSize)
       setMin((page-1)*pageSize)
      }} 
      pageSize={pageSize}
      defaultCurrent={1} 
      total={data.length} 
      />
          </>
        }
       

      </div>
     </div>
      )
}
export default Clients

