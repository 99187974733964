import React, { useState } from "react";
import { Modal, DatePicker, Button, message, Table, InputNumber } from "antd";
import { saveAs } from "file-saver";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
const { RangePicker } = DatePicker;

export default function AutoInvoice() {
  const [dateRange, setDateRange] = useState([]);
  const [visible, setVisible] = useState(false);
  const [InvoiceDate, setInvoiceDate] = useState(null);
  const [data, setData] = useState([]);
  const handleOk = () => {
    if (dateRange.length == 2) {
      setVisible(false);
      loadData(dateRange[0], dateRange[1]);
    } else {
      message.error("Please select date range");
    }
  };
  let loadData = (start, end) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      start_date: start.format("YYYY-MM-DD"),
      end_date: end.format("YYYY-MM-DD"),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://activatewm.com/portal/v1/license/expired", requestOptions)
      .then((response) => response.text())
      .then((result) => setData(JSON.parse(result).data))
      .catch((error) => message.error(error.message));
  };
  const getColorText = (text, color) => {
    return { text: text, color: color };
  };
  
  const downloadInvoice = (obj) => {
    // Define the content of the PDF document
   
    const docDefinition = {
      content: [
        {
          text: "Invoice",
          fontSize: 16,
          bold: true,
          alignment: "center",
          margin: [0, 0, 0, 20],
        },
        {
          
          columns: [{text: "Client Name: "},{text:obj.client_name,color:"rgba(126,202,221,255)"}],
          fontSize: 14,
          bold: true,
          margin: [0, 0, 0, 10],
        },
     
        { 
        columns: [{text: "Software: "},{text:obj.softName,color:"rgba(126,202,221,255)"}],
        fontSize: 14, margin: [0, 0, 0, 10] },
        {
        columns: [{text: "Quantity:"},{text:obj.renew,color:"rgba(126,202,221,255)"}]
        
        , fontSize: 14, margin: [0, 0, 0, 10] },
        {
      
          columns: [{text: "Expired Licenses: "},{text:obj.licenseName.join(","),color:"rgba(126,202,221,255)"}]
          ,
          fontSize: 14,
          margin: [0, 0, 0, 10],
        },
        {
      
          columns: [{text: "license Duration in months: "},{text:obj.month.join(","),color:"rgba(126,202,221,255)"}]
          ,
          fontSize: 14,
        
          margin: [0, 0, 0, 10],
        },
        {
         
          columns: [{text: "Expiry Dates:  "},{text:obj.expiredDates.join(","),color:"rgba(126,202,221,255)"}]
          ,
          fontSize: 14,
          margin: [0, 0, 0, 10],
        },
        {
         
          columns: [{text: "Each Price"},{text:obj.licensePrice.join(","),color:"rgba(126,202,221,255)"}]
          ,
          fontSize: 16,
          bold: true,
          
          margin: [0, 0, 0, 10],
        },
        ,
        {
         
          columns: [{text: "Total Invoice: "},{text:"$"+obj.price,color:"rgba(126,202,221,255)"}]
          ,
          fontSize: 16,
          bold: true,

          margin: [0, 0, 0, 10],
        },
        {
          text: "Invoice Date  " + obj.InvoiceDate,
          fontSize: 14,
          margin: [0, 0, 0, 10],
        },
        {
          text: "Thank you for your business!",
          fontSize: 14,
          bold: true,
          color:"green",
          alignment: "center",
        },
      ],
    };
    
    // Generate the PDF document
    const pdfDoc = pdfMake.createPdf(docDefinition);
    pdfDoc.download(obj.client_name + "-invoice.pdf"); // Download the PDF document with the specified file name
  };

  const handleDateRangeChange = (value) => {
    setDateRange(value);
  };
  const InputComponent = (props) => {
    return (
      <InputNumber
        style={{ width: 100 }}
        type="number"
        max={props.record[1].data.length}
        min={0}
        value={props.text}
        defaultValue={0}
        onChange={(e) => {
          props.handleInputChange(e, props.record);
        }}
      />
    );
  };
  const columns = [
    {
      title: "Client Name",
      dataIndex: [[1], "client_name"],
    },

    {
      title: "Software",
      dataIndex: [[1], "softName"],
    },
    {
      title: "Quantity",
      render: (rowData) => {
        return rowData[1].data.length;
      },
    },
    {
      title: "Renew",
      dataIndex: "renew",
      render: (text, record) => {
        return (
          <InputComponent
            text={text}
            record={record}
            handleInputChange={handleInputChange}
          />
        );
      },
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const handleInputChange = (e, k) => {
    const newData = [...data];
    const index = newData.findIndex((item) => k[0] === item[0]);

    newData[index].renew = parseInt(e) || 0; // only accept numeric values
    setData(newData);
  };

  return (
    <>
      <Modal
        title="Select Date Range for Expired License List"
        visible={visible}
        onOk={handleOk}
        onCancel={() => {
          setVisible(false);
        }}
        closable={false}
        footer={[
          <Button key="ok" onClick={handleOk}>
            Print
          </Button>,
          <Button
            key="cancel"
            onClick={() => {
              setVisible(false);
            }}
          >
            Close me
          </Button>,
        ]}
      >
        <RangePicker onChange={handleDateRangeChange} />
      </Modal>
      <div>
        <h1>Upcoming Renewals</h1>
        <p
          className="btn"
          onClick={() => {
            setVisible(true);
          }}
          style={{ maxWidth: "300px" }}
        >
          Select Date Intervals For Invoices
        </p>
        <Table
          rowKey={(rowData, index) => index}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={data}
        />
        <div className="invoice_div">
          <h3 style={{ marginRight: 20 }}>Invoice Date</h3>
          <div className="datepicker">
            <DatePicker
              format="YYYY-MM-DD"
              onChange={(value) => {
                setInvoiceDate(value.format("YYYY-MM-DD"));
              }}
            />
          </div>
          <p
            className="btn"
            onClick={() => {
              if (InvoiceDate) {
                data.filter((item, index) => {
                  if (selectedRowKeys.includes(index)) {
                    let client_name = item[1].client_name;
                    let softName = item[1].softName;
                    let data = item[1].data;

                    let renew = item.renew || 0;

                    let price = 0;
                    let licensePrice = [];
                    let expiredDates = [];
                    let month = [];
                    let licenseName = [];
                    for (let i = 0; i < renew; i++) {
                      //  console.log(data[i].expire_date)

                      price = Number(price) + Number(data[i].price);
                      licenseName.push(data[i].license_name);
                      licensePrice.push(data[i].price);
                      expiredDates.push(data[i].expire_date);
                      month.push(data[i].month);
                    }

                    downloadInvoice({
                      client_name,
                      softName,
                      licenseName,
                      licensePrice,
                      expiredDates,
                      month,
                      price,
                      renew,
                      total: data.length,
                      InvoiceDate,
                    });
                  }
                });
              } else {
                message.info("Please select invoice date");
              }
            }}
          >
            Generate Invoice
          </p>
        </div>
      </div>
    </>
  );
}
