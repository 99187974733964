import React from "react"

import "antd/dist/antd.css";
import {ArrowLeftOutlined} from '@ant-design/icons'
import { Form, Input, Button, Select, Tooltip, Divider, Space, message } from 'antd';
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
require('./sub.routes.css')
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const VersionCreate=()=>{
    const location=useLocation()
    const [form] = Form.useForm();
    const navigation=useNavigate()
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const onFinish = (values) => {
      if(location.state){
        axios.put('https://activatewm.com/portal/v1/options/versionsystem/update',{
           _id:location.state.data._id,
           data:{
            name:values['Version Number'],
            status:values['Status']=="active"?true:false
           }
       },{
          headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json'
         }
       }).then(data=>{

         if(data.status===200){
           message.success('Updated Successful')
         }
       }).catch(err=>{
         err.response.status===400?message.warning('Not Updated'):message.error('Server error occur')
       })
       }
       else{
      axios.post('https://activatewm.com/portal/v1/options/versionsystem/create',{
        name:values['Version Number'],
        status:values['Status']=="active"?true:false,
        customer_id:cookies.customer_id
      },{
         headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(data=>{

        if(data.status===200){
          message.success('Created')
        }
      }).catch(err=>{
        err.response.status===400?message.warning('Already Present with same name'):message.error('Server error occur')
      })
    }
      };
    
      const onReset = () => {
        form.resetFields();
      };
    
  
    return (
      <div className="main-create -create -create  w3-card ">
      <div className="header">
      <div className="back">
      <Tooltip title="Back">
      <Button type="primary" shape="circle" icon={<ArrowLeftOutlined />} onClick={()=>{
         navigation('/versionnumber',{replace:true})

      }}/>
    </Tooltip>    
      </div>
      </div>
      <Space />
      <Space />
      <Divider /> 
      <div className="data">
      <Form initialValues={location.state?{"Version Number":location.state.data.name,"Status":location.state.data.status?'active':'deactive'}:""} {...layout} form={form} name="control-hooks" onFinish={onFinish}>
      <Form.Item
        name="Version Number"
        label="Version Number Name"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="Status"
        label="Status"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder="Select a option and change input text above"
        >
          <Option value="active">Active</Option>
          <Option value="deactive">Deactive</Option>
        </Select>
      </Form.Item>
      <Space />
      <Space />
      <Divider /> 
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit" style={{marginRight:10}}>
          {location.state?'Update':'Add'} 
        </Button>
        <Button htmlType="button" onClick={onReset}>
          Reset
        </Button>
       
      </Form.Item>
    </Form>
      </div>
     </div>
      )
}
export default VersionCreate

