import { Menu } from 'antd';
import React, { useState } from 'react';
import { UserAddOutlined ,AppstoreOutlined,TeamOutlined ,MenuUnfoldOutlined,CalculatorFilled} from '@ant-design/icons';
import './menu.css'
import { useNavigate } from 'react-router-dom';
const { SubMenu } = Menu;
const rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];
const Menus=()=>{
    const navigate=useNavigate()
    const [current,setCurrent]=useState('mail')
    const handleClick = e => {
        setCurrent({ current: e.key });
      };
    const [openKeys, setOpenKeys] = React.useState(['sub1']);

    const onOpenChange = keys => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
        if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
          setOpenKeys(keys);
        } else {
          setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
      };
    return(
        <>
        <div className='horizontal'>
        <Menu onClick={handleClick} onChange={onOpenChange} selectedKeys={[current]} mode="horizontal">
        <Menu.Item key="Dashboard" icon={<AppstoreOutlined />} onClick={()=>{
          navigate('/')
        }}>
            Dashboard
        </Menu.Item>
        {/* <Menu.Item key="sub-admins" icon={<UserAddOutlined />} onClick={()=>{
          navigate('admins')
        }}>
           Sub-Admins
        </Menu.Item> */}
        <Menu.Item key="clients" icon={<TeamOutlined />}onClick={()=>{
          navigate('clients')
        }}>
           Clients
        </Menu.Item>
        <Menu.Item key="dealers" icon={<TeamOutlined />}onClick={()=>{
          navigate('dealers')
        }}>
           Dealers 
        </Menu.Item>
        <SubMenu key="option" icon={<MenuUnfoldOutlined/>} title="Option">
          <Menu.ItemGroup title="">
            <Menu.Item key="setting:1" icon={<MenuUnfoldOutlined/>}onClick={()=>{
              navigate('accountingsystem')
            }}>Accounting System</Menu.Item>
            <Menu.Item key="setting:2" icon={<MenuUnfoldOutlined/>}onClick={()=>{
              navigate('operatingsystem')
            }}>Operating System</Menu.Item>
            <Menu.Item key="setting:3" icon={<MenuUnfoldOutlined/>}onClick={()=>{
              navigate('versionnumber')
            }}>Version Number</Menu.Item>
            <Menu.Item key="setting:4" icon={<MenuUnfoldOutlined/>}
            onClick={()=>{
              navigate('devicetype')
            }}
            >Device Type</Menu.Item>
            <Menu.Item key="setting:5" icon={<MenuUnfoldOutlined/>} onClick={()=>{
              navigate('databasetype')
            }}>Database Type</Menu.Item>
             <Menu.Item key="setting:6" icon={<MenuUnfoldOutlined/>} onClick={()=>{
              navigate('SoftwareType')
            }}>Software Type</Menu.Item>
            <Menu.Item key="setting:7" icon={<MenuUnfoldOutlined/>} onClick={()=>{
              navigate('taxes')
            }}>Add Taxes</Menu.Item>
          </Menu.ItemGroup>
          </SubMenu>
          <Menu.Item title='Auto Invoice' key="autoinvoice" icon={<CalculatorFilled />}
          onClick={()=>{
            navigate('autoinvoice')
          }}
          >
            Auto Invoice
          </Menu.Item>
         </Menu>
         
       </div>

        <div className='vertical'>

        </div>
        
        </>
    )
}
export default Menus