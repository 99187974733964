import { Image, Modal } from "antd";
import React, { useEffect, useState } from "react";
import './home.css'
import PropTypes from 'prop-types';
import logo from '../logo.png'
import Menus from "./menu";
import { Outlet, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import moment from "moment";

const Home=({setToken})=>{
    const navigate=useNavigate()
    const [cookies, setCookie,removeCookie] = useCookies(['user']);
    const [visible,setvisible]=useState(false)
   
    return(
        <div>
            <div className="upper-header">
            <img className="img" preview={true} src={logo}/> 
            <div className=" left-corner">
            <p style={{width:160}}>Expiry Date <br/>{cookies.expire_date} </p>
            <p className="signout">Compnay Name {cookies.license_name}</p>
            <p className="signout" onClick={()=>{
                setvisible(true)
            }}>Sign out</p> 
            </div> 
            </div>
            <div className="nav-header">
                <Menus />
            </div>
            <div className="main">
             <Outlet />
            </div>
            <Modal
           title=""
          visible={visible}
          onOk={()=>{
             removeCookie('id')
              setvisible(false)
              navigate('/',{replace:true})
              setToken(null)
          }}
          onCancel={()=>setvisible(false)}
          okText="Yes"
          cancelText="No"
          closable={false}
          width={240}
        >
        <p>Are you sure to logout?</p>
        </Modal>
        </div>
    )
}

export default Home
Home.propTypes = {
  setToken: PropTypes.func.isRequired
};