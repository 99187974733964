import React, { useEffect, useState } from "react"
import {Input, message, Space, Table, Tag} from 'antd';
import "antd/dist/antd.css";
import {PlusOutlined} from '@ant-design/icons'
import { useNavigate } from "react-router-dom";
import Column from "antd/lib/table/Column";
import moment from "moment";
import axios from "axios";
import { useCookies } from "react-cookie";
require('./routes.css')

const Admins=()=>{
  const [data,setData]=useState([])
  const [temp,setTemp]=useState([])
  const [cookies,setCookies]=useCookies('user')
  const fetch=async()=>{
      const hide = message.loading('Action in progress..', 0);
      try {
        const payload=await axios.get('https://activatewm.com/portal/v1/user/get/')
        if(payload){
          setTimeout(hide,100)
          setData(payload.data)
          setTemp(payload.data)
        }
      } catch (error) {
          message.error('internal server issue')
      }
  }
  useEffect(()=>{
    fetch()
  },[])
  const navigation=useNavigate()
  const onSearch = value => {
    if(value.length==0){
      setData(temp)
    }else{
     let matched=temp.filter((item)=>{
        if(item.name.match(value) ||item.email.match(value)){
          return item
        }
      })
      setData(matched)
    }
  };
    return (
      <div className="main w3-card" >
      <div className="header">
      <h5>Admins</h5>
      <div className="create">
      <PlusOutlined className='plus' onClick={()=>{
         navigation('/admins/create')
      }}/>
      <Input.Search placeholder="search by name" allowClear onSearch={onSearch} style={{ width: 200 }} />
      </div>
      </div>
      <div className="admin-section">
      <Table className='tab' key='index' pagination={{pageSize:5}} className="tab" dataSource={data} render={(att)=>{
      }}>
      <Column title="Name" dataIndex="name" key="name" />
      <Column title="Role" dataIndex="role" render={(value,record)=>{
        return < Tag>{value==true?'Super Admin':'Sub Admin'}</Tag>
      }} />
      <Column title="Status" dataIndex="status" render={(value,record)=>{
        return < Tag>{value==true?'active':'disabled'}</Tag>
      }}/>
      <Column title='Actions' render={(value,record)=>{
        return<Space>
           <Tag style={{cursor:'pointer'}} color={'Green'} onClick={()=>{
           if(record.admin_id==cookies.id){
              navigation('/admins/create',{
                state:{
                data:record
              }
              })
            }else{
              if(cookies.super!='false'){
                navigation('/admins/create',{
                  state:{
                  data:record
                }
                })
              }else{
                message.info('only super user can edit other accounts',1)
              }
            }
           }}>Edit</Tag>
          <Tag style={{cursor:'pointer'}} color={'Red'} onClick={()=>{
            if(record.admin_id==cookies.id){
              message.warn('can not delete own account',.5)
            }else{
              if(cookies.super!='false'){
                axios.delete('https://activatewm.com/portal/v1/user/delete/?admin_id='+record.admin_id).then(res=>{
                    fetch()
             }).catch(error=>{
               fetch()
             })
            }else{
              message.error('Only super Admin can delete',0.5)
            }
            }
          }}>Delete</Tag>
        </Space> 
      }}  />
      <Column title='Created-At'  dataIndex='createdAt' key='createdAt' render={(value,record)=><p>{moment(value).format('DD-MM-YYYY')}</p>}  />
  </Table>
      </div>
     </div>
      )
}
export default Admins

